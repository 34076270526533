<template>
  <main>
    <transition name="fade">
      <div v-if="carouselItems || offerings || features">
        <div class="container-fluid">
          <div
            id="myCarousel"
            class="carousel slide"
            data-bs-ride="carousel"
            data-bs-interval="10000"
          >
            <div class="carousel-indicators">
              <div
                v-for="(carouselItem, index) in carouselItems"
                :key="carouselItem.id"
              >
                <button
                  type="button"
                  data-bs-target="#myCarousel"
                  :data-bs-slide-to="index"
                  :class="{ active: index == 0 }"
                  :aria-current="index == 0"
                  :aria-label="carouselItem.id"
                ></button>
              </div>
            </div>
            <div class="carousel-inner">
              <div
                v-for="(carouselItem, index) in carouselItems"
                :key="carouselItem.id"
                class="carousel-item"
                :class="{ active: index == 0 }"
              >
                <!-- BACKGROUND IMAGE -->
                <div class="d-flex justify-content-center">
                  <img focusable="false" :src="carouselItem.imageURL" />
                </div>
                <div class="carousel-caption">
                  <div
                    style="
                  background: rgba(0, 0, 0, 0.6);
                  padding: 40px;
                  max-width: 600px;
                "
                  >
                    <h4>
                      {{ carouselItem.teaser }}
                    </h4>
                    <h2>
                      {{ carouselItem.headline }}
                    </h2>
                    <p>{{ carouselItem.subheading }}</p>
                    <p>
                      <router-link
                        :to="{ name: 'Offerings' }"
                        class="btn btn-lg btn-primary mb-2 mx-2"
                        >invest</router-link
                      >
                      <router-link
                        class="btn btn-lg btn-primary-clear mb-2 mx-2"
                        to="/rh/raise"
                        >Raise</router-link
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <!-- Wrap the rest of the page in another container to center all the content. -->
        <div class="container marketing">
          <div class="row mb-3">
            <div class="col-md-12 text-center">
              We help early-stage companies raise money to grow, and let anyone
              invest in them. Investors can back founders, invest in potential
              growth, and support communities.
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-12 text-center">
              <h2>Round Here’s Current Offerings.</h2>
            </div>
          </div>
          <div
            v-if="offerings"
            class="row row-cols-1 row-cols-sm-2 row-cols-md-3"
          >
            <div v-for="offering in offerings" :key="offering.id" class="col">
              <OfferingSingle :offering="offering" />
            </div>
          </div>
          <div class="text-center" v-if="offerings && offerings.length == 0">
            <p>
              There are no active offerings available for investment at this
              time. There are some offerings in the works, so check back soon.
            </p>
          </div>
          <div v-else class="row mb-3">
            <div
              class="col-md-12 themed-grid-col"
              style="text-align:center;padding-top:30px;"
            >
              <router-link
                class="btn btn-lg btn-primary"
                :to="{ name: 'Offerings' }"
                >Explore All</router-link
              >
            </div>
          </div>

          <!-- START THE FEATURETTES -->

          <hr class="featurette-divider" />
          <div class="error">{{ errorFeature }}</div>
          <div v-for="(feature, index) in features" :key="feature.id">
            <div class="row featurette">
              <div class="col-md-7" :class="{ 'order-md-2': index % 2 != 0 }">
                <h2 class="featurette-heading">
                  {{ feature.headline }}
                  <span class="text-muted">{{ feature.subheading }}</span>
                </h2>
                <p class="lead" v-html="feature.description"></p>
              </div>
              <div class="col-md-5" :class="{ 'order-md-1': index % 2 != 0 }">
                <img class="img-fluid featurette-img" :src="feature.imageURL" />
              </div>
            </div>
            <hr class="featurette-divider" />
          </div>
          <!-- /END THE FEATURETTES -->
          <div class="row mb-3">
            <div class="col-md-12 text-center">
              INVESTMENTS ON THIS FUNDING PORTAL ARE SPECULATIVE, ILLIQUID, AND
              INVOLVE A HIGH DEGREE OF RISK, INCLUDING THE RISK OF LOSS OF YOUR
              INVESTMENT
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- /.container -->
  </main>
  <Footer v-if="offerings" />
</template>

<script>
import Footer from "@/components/navigation/Footer.vue";
import getCollection from "@/composables/getCollection";
import OfferingSingle from "@/components/offerings/OfferingSingle.vue";

export default {
  name: "Home",
  components: { OfferingSingle, Footer },
  setup() {
    // const { error: errorFounder, documents: founders } = getCollection(
    //   "rhFounders",
    //   [["order", ">", 0]],
    //   ["order", "asc"]
    // );
    const { error: errorFeature, documents: features } = getCollection(
      "rhFeatures",
      [["order", ">", 0]],
      ["order", "asc"]
    );
    const { error: errorCarousel, documents: carouselItems } = getCollection(
      "rhCarousels",
      [["order", ">", 0]],
      ["order", "asc"]
    );
    const { error: errorOfferings, documents: offerings } = getCollection(
      "offerings",
      [
        ["featured", ">", 0],
        ["published", "==", true]
      ],
      ["featured", "desc"]
    );

    return {
      errorOfferings,
      offerings,
      errorCarousel,
      carouselItems,
      errorFeature,
      features
      // errorFounder,
      // founders,
    };
  }
};
</script>
<style scoped>
/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 2rem;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  top: 6rem;
  z-index: 10;
}

.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  min-height: 42rem;
  /* margin: auto; */
}

.carousel-item {
  min-height: 600px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.carousel-item h2 {
  color: white;
}

.carousel-item h4 {
  color: rgb(190, 190, 190);
}

/* .carousel-control-prev-icon,
.carousel-control-next-icon {
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid var(--primary);
  background-color: var(--primary);
} */

.featurette-img {
  overflow: hidden;
  border-radius: 10px;
}

.founder-img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
}

.fade-enter-from {
  opacity: 0;
}
.fade-enter-active {
  transition: all 2s ease;
}

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }
}
</style>
